<template>
  <div class="widget-inner">
    <div class="stats" v-if="(data.hideStats === true) ? false : true">
      <div>
        <span>{{$formatNumber(data.shoutouts)}}</span>
        <label>Shoutouts sent</label>
      </div>
      <div>
        <span>{{$formatNumber(data.awards)}}</span>
        <label>Awards given</label>
      </div>
      <div>
        <span>{{$formatNumber(data.engagement.active_users)}}</span>
        <label>Users engaged</label>
      </div>
    </div>
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-gauge';

export default {
  props: ['data'],
  mounted() {
    this.renderChart();
    this.$emit('loaded');
  },
  data() {
    return {
      chart: null
    }
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.chart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'gauge',
        data: {
          datasets: [
            {
              value: Math.round( (parseInt(this.data.engagement.active_users)/parseInt(this.data.engagement.user_count)) * 100 ) || 0,
              data: [20, 50, 100],
              backgroundColor: [
                '#CE4257',
                '#E6B332',
                '#3C6E71'
              ]
            }
          ]
        },
        options: {
          cutoutPercentage: 70,
          needle: {
            radiusPercentage: 1,
            widthPercentage: 2,
            lengthPercentage: 50,
            color: '#40342D'
          },
          valueLabel: {
            display: true,
            fontSize: 18,
            backgroundColor: '#EFECEA',
            color: '#796f69',
            bottomMarginPercentage: 13,
            formatter: function(val) {
              return `${val}%`
            }
          },
          layout: {
            padding: {
              bottom: 4
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stats {
  display: flex;
  justify-content: center;
  margin: 0 -10px 25px;

  > div {
    text-align: center;
    padding: 0 10px;
    > span {
      font-size: 18px;
      line-height: 1.2em;
    }
    > label {
      display: block;
      margin: 0;
      font-size: 14px;
      color: $muted-text;
      line-height: 1.2em;
    }
  }
}
.chart-elem {
  position: relative;
}
</style>